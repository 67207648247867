<div *ngIf="authService.currentUser() == '' ">
  <app-landing></app-landing>
</div>

<div  *ngIf="authService.currentUser() != '' " class="flex flex-col h-screen bg-slate-50">
  <app-header></app-header>

  <div
  class="flex-1 overflow-hidden bg-slate-200 grid grid-cols-5 gap-4"
    style="height: calc(100vh - var(--header-footer-height))"
  >
    <div class="col-span-1">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-span-4" style="height: calc(100vh - 14vh)">
      <div
        class="overflow-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-[rgb(0,100,162)] scrollbar-track-slate-300 h-full"
      >
        <div class="bg-white shadow-lg rounded-lg p-6 m-6">
          <div [hidden]="authService.currentUser() != ''">
            <app-landing></app-landing>
          </div>
          <div
            [hidden]="authService.currentUser() == ''"
            class="bg-white shadow-lg rounded-lg p-6 m-6"
          >
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="authService.currentUser() != undefined">
    <app-footer></app-footer>
  </div>
</div>

<style>
  :root {
    --header-footer-height: 100px;
  }
</style>
