import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../core/services/auth/auth.service';
import { MatIcon, MatIconModule } from '@angular/material/icon';
// import { AuthService } from '../auth.service';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TranslateModule,MatIcon,MatIconModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(public auth: AuthService){}
}
