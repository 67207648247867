<div class="relative h-16 sticky" style="background-color: rgb(0,100,162)">
  <!-- <header class="bg-blue-800 text-white absolute inset-x-0 top-0 h-16"> -->
  <header
    [class]="
      auth.currentUser()
        ? ' text-white absolute inset-x-0 top-0 h-18'
        : ''
    "
  >
    <nav [hidden]="auth.currentUser() == ''">
      <!-- Other navigation items -->
       <div class="float-left ml-[15px]">
        <img
        src="../../../assets/images/logo.png"
        alt="Logo"
        class="mx-auto mb-4 w-24 h-auto"
      />
       </div>
      <div class="float-end mr-[20px] mt-[22px] flex">
    <div>
      <mat-icon>power_settings_new</mat-icon>
    </div>
    <div class="-mt-[1px]">
      <button (click)="auth.logout()">Logout</button>
    </div>
      </div>
    </nav>
  </header>
</div>
